//Copied from alexey: https://github.com/iotaledger/industry-marketplace/blob/master/YellowPages/client/src/assets/styles/drop-selector.scss
$font-family-icons: 'IOTA icons';

@mixin icon($size: null) {
  font-family: $font-family-icons;
  font-weight: 300;
  @if ($size) {
    font-size: $size;
    line-height: $size;
  }
}

%icon-up-down {
  content: '🔽';
}

.icon-up-down {
  &::after {
    @extend %icon-up-down;
    @include icon(12px);
  }
}

.drop-selector {
    position: relative;
  
    .drop-selector-title {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      padding-right: 18px;
      padding-left: 18px;
      background: #eeecec;
      border-radius: 6px;
      cursor: pointer;
      //width: 29px;
      width: 100px;
  
      .drop-selector-title__text {
        font-size: 12px;
        color: #485776;
        font-weight: bold;
        line-height: 15px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
      }
  
      .drop-selector-title__icon {
        &::before {
          @extend %icon-up-down;
  
          position: relative;
          left: 5px;
          color: #485776;
  
          @include icon(11px);
        }
      }
    }
  
    .drop-selector-list {
      position: absolute;
      top: 37px;
      width: 100px;
      max-height: 0;
      margin: 1px 0px 0px 0px;
      padding: 0;
      overflow: hidden;
      list-style: none;
      transition: 0.1s ease-out;
      transition-property: max-height;
      background-color: #EEF2FA;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      cursor: pointer;
  
      .drop-selector-list-item {
        button {
          background: #EEF2FA;
          display: block;
          padding-right: 18px;
          padding-left: 18px;
  
          height: 100%;
          color: #485776;
          font-weight: bold;
          font-size: 12px;
          line-height: 3;
          text-transform: uppercase;
          text-decoration: none;
  
          &:hover {
            background-color: #fafafa;
          }
        }
  
        &:hover {
          background-color: #fafafa;
        }
      }
    }
  
    &.drop-selector__expanded {
      .drop-selector-list {
        height: auto;
        max-height: 42px;
        transition: 0.1s ease-in;
        transition-property: max-height;
        border-bottom-width: 1px;
      }
    }
  }