.logo.demo-page {
    position: absolute;
    top: 30px;
    left: 40px;
    z-index: 10;

    @include breakPoint(small) {
        top: 10px;
        left: 10px;

        img {
            width: 80px;
        }
    }
}

.demo-drop-selector {
    position: absolute;
    top: 37px;
    right: 40px;
    z-index: 10;

    @include breakPoint(small) {
        top: 5px;
        right: 15px;
    }
}

.demo-select-page-drop-selector {
    position: absolute;
    top: 37px;
    right: 23px;
    z-index: 10;

    @include breakPoint(small) {
        top: 5px;
        right: 15px;
    }
}



.demo-intro {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 989px;
    align-items: center;
    margin: 160px auto;
    z-index: 1;

    @include breakPoint(medium) {
        flex-direction: column;
    }

    .random-element-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    h2 {
        font-weight: bold;
        line-height: 40px;
    }

    h3 {
        font-weight: 500;
        margin: 20px 0;
    }

    @include breakPoint(small) {
        h1 {
            font-size: 34px;
            line-height: 48px;
        }
        h2 {
            font-size: 32px;

            &.highlight {
                line-height: 6px;
                margin-top: 12px;
                border-bottom: 20px solid #B5F3D8;
            }
        }
        h4 {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 30px;
        }
        h5 {
            font-size: 21px;
            line-height: 24px;
        }
        p {
            font-size: 15px;
            line-height: 30px;
        }
    }

    .cta {
        @include themify($themes) {
            background: themed('primaryColor');
        }
        z-index: 10;
        border-radius: 35px;
        height: 55px;
        padding: 10px 40px !important;

        > span {
            color: #ffffff;
            @include themify($themes) {
                font-family: themed('fontFamily');
            }
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
        }
    }

    .todos {
        width: 65%;
        min-width: 485px;

        .heading {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        @include breakPoint(small) {
            min-width: unset;

            .heading {
                flex-direction: column;
                align-items: flex-start;

                .highlight {
                    margin-top: 0;
                }
            }
        }

        h2 {
            &.highlight {
                border-bottom: 24px solid #B5F3D8;
                line-height: 6px;
                margin-top: 24px;
                font-weight: 900;
            }
        }

        ul {
            list-style-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 22 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0V9Z' fill='%234247DC'/%3E%3C/svg%3E");
            padding-left: 20px;
            margin-bottom: 50px;
    
            li {
                @include themify($themes) {
                    font-family: themed('fontFamilyHeadings');
                }
                font-weight: bold;
                line-height: 48px;
                margin-top: 2px;
            }
        }
    }

    .image-wrapper {
        display: inline-block;
        position: relative;
        width: 100%;
        max-width: 50%;

        @include breakPoint(medium) {
            max-width: 100%;
            text-align: center;
        }

        img {
            max-width: 100%;
            z-index: 2;
        }
    }

    &.app {
        flex-direction: column;
        min-height: 500px;

        .app-content-wrapper {
            width: 60%;
            min-width: 450px;
            position: relative;
            align-self: flex-start;

            h3 {
                margin: 45px 50px 45px 0;
            }

            @include breakPoint(medium) {
                align-self: center;
            }

            @include breakPoint(small) {
                min-width: unset;
                width: 90%;

                h3 {
                    margin-right: 20px;
                }
            }
        }

        .phone {
            position: absolute;
            right: 20px;
            top: 70px;

            @include breakPoint(medium) {
               position: static;
            }
        }
    
        .note {
            font-weight: bold;
            font-size: 17px;
            line-height: 24px;
            color: #30323D;
            margin: 20px 0 50px;

            @include breakPoint(medium) {
                margin-top: 50px;
                align-self: center;
                min-width: 450px;
            }

            @include breakPoint(small) {
                min-width: unset;
                width: 90%;
            }
        }
    }

    .dots-top {
        position: fixed;
        top: -250px;
        left: 100px;
        z-index: -1;
        transform: rotate(270deg);
    }

    .dots-bottom {
        position: fixed;
        bottom: -210px;
        left: -210px;
        z-index: 0;
        transform: rotate(90deg);
        @include breakPoint(medium) {
           display: none;
        }
    }

    .ellipse {
        position: fixed;
        bottom: -120px;
        left: 50px;
        z-index: -1;
        @include breakPoint(medium) {
           display: none;
        }
    }
}

.demo-selector {
    max-width: 1400px;
}

.demo-select-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    max-width:1400px;
    align-items: center;
    z-index: 1;

    @include breakPoint(medium) {
        flex-direction: column;
    }

    .demo-card-wrapper {
        width: 410px;
        height: 560px;
        background: #F2F5FA;
        border-radius: 10px;
        margin-bottom: 40px;

        @include breakPoint(medium) {
            height: auto;
        }

        img {
            width: 410px;
        }

        @media (max-width: 430px) {
            width: auto;
            padding: 15px;
            margin: 0 10px 20px 10px;

            img {
                width: 100%;
            }
        }
    }

    .demo-card-content {
        margin: 27px 12px 0px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include breakPoint(medium) {
            margin-bottom: 10px;
        }

        @media (max-width: 430px) {
            margin: 10px 5px 10px 5px;
        }

        h3 {
            margin: 0;
            font-weight: bold;
        }

        p {
            margin: 10px 0 10px;
            height: 130px;

            @include breakPoint(medium) {
                height: auto;
            }
        }

        .bottom {
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            // padding-right: 10px;
        }
        .partners {
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            img {
                display: flex;
                justify-content: space-between;
                margin: 5px 0px 0px 0px;
                height: 40px;
                width: auto;
                padding: 5px;
            }
        }
    }
}
