.next-step-drawer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    word-break: break-word;
    background-color: #FFFFFF;
    min-height: 182px;
    padding: 30px;
    z-index: 0;
    position: fixed;
    bottom: 0;
    width: calc(100% - 320px);
    left: 0;

    @include breakPoint(medium) {
        width: 100%;
        text-align: center;
    }

    h3 {
        color: #F17105;  
    }

    p {
        max-width: 570px;
        width: 100%;
        margin: 20px 0;
        text-align: center;
    }

    &.completed {
        h3 {
            color: #35C0A1;  
        }

        p {
            color: #4D4D4D;  
        }
    }
}
