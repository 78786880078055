.app-picker {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > * {
        z-index: 1;
    }

    position: fixed;
    width: calc(100% - 320px);
    height: calc(100% - 100px);
    z-index: 2;
    margin-top: -50px;
    padding-top: 18px;

    @include breakPointHeight(short) {
        position: relative;
    }

    &__scroller {
        overflow-y: auto;
        position: relative;
        z-index: 1;
    }

    h1 {
        font-weight: bold;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #142037;
        max-width: 420px;
    }

    h1, h2 {
        margin-bottom: 20px;
        text-align: center;
    }

    h2 {
        @include themify($themes) {
            color: themed('trinaryColor');  
            font-size: themed('h2TextSize');
            line-height: themed('h2LineHeight');
        }
    }

    p {
        font-size: 21px;
        line-height: 30px;
        text-align: center;
        max-width: 380px;
    }

    .avatar2 {
        position: absolute;
        top: 130px;
        left: 55px;
        z-index: 1;

        @include breakPoint(small) {
            left: 0;
        }
    }

    .avatar1 {
        position: absolute;
        top: 350px;
        right: 40px;
        z-index: 1;

        @include breakPoint(small) {
            right: 0;
        }
    }

    // .app-cta-wrapper {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-evenly;
    //     width: 100%;
    //     max-width: 380px;
    //     margin: 40px auto 50px;

    //     a {
    //         z-index: 3;
    //     }
    // }
    
    .dots {
        position: absolute;
        right: 0;
        top: -50px;
        z-index: -1;

        @include breakPoint(medium) {
            right: -210px;
        }

        @include breakPoint(small) {
            display: none;
        }

        @include breakPointHeight(short) {
            right: -210px;
        }
    }

    .circle {
        position: absolute;
        bottom: -250px;
        z-index: 0;
        width: 800px;

        @include breakPoint(small) {
            width: 100vw;
        }
    }

    .cta {
        margin-top: 0;
    }

    .apps {

        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
        max-height: 100%;
        padding: 10px;

        .app {

            padding: 15px;

            &__wrapper {
                display: flex;
                flex-direction: row;
                height: 250px;
                background-color: #FFFFFF;
                padding: 15px 0 15px 15px;
                border-radius: 5px;
                border: 1px solid rgba(0,0,0,0.20);
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
            }

            &__column {
                display: flex;
                flex-direction: column;
                min-width: 200px;
            }

            &__content {
                padding-top: 10px;
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: space-between;
            }

            &__image {
                margin-left: 25px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
    }
    
}

.wallet-modal {
    &__by {
        margin: 10px 0 20px 0;
    }

    &__qr {
        margin-top: 20px;

        .ant-qrcode {
            padding-left: 0;
        }
    }
}

.cta-section {
    position: fixed;
    bottom: 0;
    height: 165px;
    width: calc(100% - 320px);
    left: 0;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 2;
    -webkit-box-shadow: inset -47px 0px 20px -59px rgba(0,0,0,0.75);
    -moz-box-shadow: inset -47px 0px 20px -59px rgba(0,0,0,0.75);
    box-shadow: inset -47px 0px 20px -59px rgba(0,0,0,0.75);
    .subtitle {
        font-weight: 500;
        font-size: 21px;
        width: 90%;
        text-align: center;
    }

    @include breakPoint(medium) {
        width: 100%;
    }
}

.cta-section-extended {
    @extend .cta-section;
    bottom: 80px;    
}
