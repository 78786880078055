$font-family-companyHouse: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-snsBank: 'Maven Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-insurance: 'Maven Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-demo: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-demo-headings: 'Metropolis', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

$primary-color-companyHouse: #3B6AC5;
$secondary-color-companyHouse: #4247DC;
$trinary-color-companyHouse: #333333;
$primary-font-color-companyHouse: #142037;
$secondary-font-color-companyHouse: #6F6F6F;
$page-background-companyHouse: #EEF2FA;

$primary-color-snsBank: #64CDF5;
$secondary-color-snsBank: #562A71;
$trinary-color-snsBank: #64CDF5;
$primary-font-color-snsBank: #142037;
$secondary-font-color-snsBank: #BBBBBB;
$page-background-snsBank: #EEF2FA;

$primary-color-insurance: #F39B26;
$secondary-color-insurance: #562A71;
$trinary-color-insurance: #64CDF5;
$primary-font-color-insurance: #142037;
$secondary-font-color-insurance: #BBBBBB;
$page-background-insurance: #EEF2FA;

$primary-color-demo: #4247DC;
$secondary-color-demo: #3B6AC5;
$trinary-color-demo: #25C1B6;
$primary-font-color-demo: #142037;
$secondary-font-color-demo: #BBBBBB;
$page-background-demo: #EEF2FA;

$link-color: #306ADC;
$notice-color: #4247DC;
$form-selector-color: #339BB6;

$themes: (
    demo: (
        fontFamily: $font-family-demo,
        fontFamilyHeadings: $font-family-demo-headings,

        defaultTextSize: 16px,
        defaultLineHeight: 27px,

        primaryColor: $primary-color-demo,
        secondaryColor: $secondary-color-demo,
        trinaryColor: $trinary-color-demo,
        primaryFontColor: $primary-font-color-demo,
        secondaryFontColor: $secondary-font-color-demo,
        pageBackground: $page-background-demo,

        h1TextSize: 44px,
        h1LineHeight: 57px,
        h2TextSize: 36px,
        h2LineHeight: 57px,
        h3TextSize: 21px,
        h3LineHeight: 30px,
        h4TextSize: 16px,
        h4LineHeight: 24px,
        h5TextSize: 21px,
        h5LineHeight: 24px,

        buttonBackgroundColor: $primary-color-demo,
        buttonHeight: 48px,
        buttonBorderRadius: 100px,

    ),
    oem: (
        fontFamily: $font-family-companyHouse,
        fontFamilyHeadings: $font-family-companyHouse,

        defaultTextSize: 16px,
        defaultLineHeight: 24px,

        primaryColor: $primary-color-companyHouse,
        secondaryColor: $secondary-color-companyHouse,
        trinaryColor: $trinary-color-companyHouse,
        primaryFontColor: $primary-font-color-companyHouse,
        secondaryFontColor: $secondary-font-color-companyHouse,
        linkColor: $link-color,
        noticeColor: $notice-color,
        formSelectorColor: $form-selector-color,
        pageBackground: $page-background-companyHouse,

        h1TextSize: 36px,
        h1LineHeight: 42px,
        h2TextSize: 24px,
        h2LineHeight: 30px,
        h3TextSize: 18px,
        h3LineHeight: 20px,
        h4TextSize: 16px,
        h4LineHeight: 18px,
        h5TextSize: 14px,
        h5LineHeight: 15px,

        buttonBackgroundColor: $primary-color-companyHouse,
        buttonHeight: 43px,
        buttonBorderRadius: 4px,

        headerBackgroundColor: #0f7732,
        headerHeight: 60px,
        headerLogoAlign: left,
        headerLogoHeight: 24px,

        footerBackgroundColor: #0f7732,
        footerHeight: 76px,
        footerLogoAlign: center,
        footerLogoHeight: 24px,
    ),
    ta: (
        fontFamily: $font-family-companyHouse,
        fontFamilyHeadings: $font-family-companyHouse,

        defaultTextSize: 16px,
        defaultLineHeight: 24px,

        primaryColor: $primary-color-companyHouse,
        secondaryColor: $secondary-color-companyHouse,
        trinaryColor: $trinary-color-companyHouse,
        primaryFontColor: $primary-font-color-companyHouse,
        secondaryFontColor: $secondary-font-color-companyHouse,
        linkColor: $link-color,
        noticeColor: $notice-color,
        formSelectorColor: $form-selector-color,
        pageBackground: $page-background-companyHouse,

        h1TextSize: 36px,
        h1LineHeight: 42px,
        h2TextSize: 24px,
        h2LineHeight: 30px,
        h3TextSize: 18px,
        h3LineHeight: 20px,
        h4TextSize: 16px,
        h4LineHeight: 18px,
        h5TextSize: 14px,
        h5LineHeight: 15px,

        buttonBackgroundColor: $primary-color-companyHouse,
        buttonHeight: 43px,
        buttonBorderRadius: 4px,

        headerBackgroundColor: #032F5D,
        headerHeight: 60px,
        headerLogoAlign: left,
        headerLogoHeight: 24px,

        footerBackgroundColor: #032F5D,
        footerHeight: 76px,
        footerLogoAlign: center,
        footerLogoHeight: 24px,
    ),
    psp: (
        fontFamily: $font-family-snsBank,
        fontFamilyHeadings: $font-family-snsBank,

        defaultTextSize: 16px,
        defaultLineHeight: 24px,

        primaryColor: $primary-color-snsBank,
        secondaryColor: $secondary-color-snsBank,
        trinaryColor: $trinary-color-snsBank,
        primaryFontColor: $primary-font-color-snsBank,
        secondaryFontColor: $secondary-font-color-snsBank,
        linkColor: $link-color,
        noticeColor: $notice-color,
        formSelectorColor: $form-selector-color,
        pageBackground: $page-background-snsBank,

        h1TextSize: 36px,
        h1LineHeight: 42px,
        h2TextSize: 24px,
        h2LineHeight: 28px,
        h3TextSize: 24px,
        h3LineHeight: 28px,
        h4TextSize: 18px,
        h4LineHeight: 20px,
        h5TextSize: 15px,
        h5LineHeight: 17px,

        buttonBackgroundColor: $primary-color-snsBank,
        buttonHeight: 51px,
        buttonBorderRadius: 4px,

        headerBackgroundColor: #562A71,
        headerHeight: 100px,
        headerLogoAlign: left,
        headerLogoHeight: 39px,

        footerBackgroundColor: #562A71,
        footerHeight: 80px,
        footerLogoAlign: right,
        footerLogoHeight: 24px,
    ),
    plo: (
        fontFamily: $font-family-insurance,
        fontFamilyHeadings: $font-family-insurance,

        defaultTextSize: 16px,
        defaultLineHeight: 24px,

        primaryColor: $primary-color-insurance,
        secondaryColor: $secondary-color-insurance,
        trinaryColor: $trinary-color-insurance,
        primaryFontColor: $primary-font-color-insurance,
        secondaryFontColor: $secondary-font-color-insurance,
        linkColor: $link-color,
        noticeColor: $notice-color,
        formSelectorColor: $form-selector-color,
        pageBackground: $page-background-insurance,

        h1TextSize: 36px,
        h1LineHeight: 42px,
        h2TextSize: 24px,
        h2LineHeight: 28px,
        h3TextSize: 24px,
        h3LineHeight: 28px,
        h4TextSize: 18px,
        h4LineHeight: 20px,
        h5TextSize: 15px,
        h5LineHeight: 17px,

        buttonBackgroundColor: $primary-color-insurance,
        buttonHeight: 51px,
        buttonBorderRadius: 4px,

        headerBackgroundColor: #562A71,
        headerHeight: 100px,
        headerLogoAlign: left,
        headerLogoHeight: 39px,

        footerBackgroundColor: #562A71,
        footerHeight: 80px,
        footerLogoAlign: right,
        footerLogoHeight: 24px,
    ),
);