.scan-qr-page-wrapper, .app-download {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > * {
        z-index: 1;
    }

    .qr-code {
        padding: 40px;
        box-shadow: 0px 0px 30px 0px rgba(72, 87, 118, 0.4);
        background: #FFFFFF;
        border-radius: 22px;
        // z-index: 4;
        margin: 50px 0 20px 0;

        &__link {
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }

    .cta {
        margin-top: 30px;
    }

    .spinner {
        margin: 30px;
    }

    h1, h2 {
        margin-bottom: 20px;
        text-align: center;
    }

    h2 {
        @include themify($themes) {
            color: themed('trinaryColor');  
            font-size: themed('h2TextSize');
            line-height: themed('h2LineHeight');
        }
    }

}

.cta-section {
    position: fixed;
    bottom: 0;
    height: 165px;
    width: calc(100% - 320px);
    left: 0;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 2;
    -webkit-box-shadow: inset -47px 0px 20px -59px rgba(0,0,0,0.75);
    -moz-box-shadow: inset -47px 0px 20px -59px rgba(0,0,0,0.75);
    box-shadow: inset -47px 0px 20px -59px rgba(0,0,0,0.75);
    .subtitle {
        font-weight: 500;
        font-size: 21px;
        width: 90%;
        text-align: center;
    }

    @include breakPoint(medium) {
        width: 100%;
    }
}

.cta-section-extended {
    @extend .cta-section;
    bottom: 80px;    
}

.app-download {
    position: fixed;
    width: 100%;
    z-index: 2;
    margin-top: 18px;

    @include breakPointHeight(short) {
        position: relative;
    }

    h1 {
        font-weight: bold;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #142037;
        max-width: 420px;
    }

    p {
        font-size: 21px;
        line-height: 30px;
        text-align: center;
        max-width: 380px;
    }

    .app-cta-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        max-width: 380px;
        margin: 40px auto 50px;

        a {
            z-index: 3;
        }
    }
    
    .dots {
        position: absolute;
        right: 0;
        top: -50px;
        z-index: -1;

        @include breakPoint(medium) {
            right: -210px;
        }

        @include breakPoint(small) {
            display: none;
        }

        @include breakPointHeight(short) {
            right: -210px;
        }
    }

    .circle {
        position: absolute;
        bottom: -250px;
        z-index: 0;
        width: 800px;

        @include breakPoint(small) {
            width: 100vw;
        }
    }

    .cta {
        margin-top: 0;
    }


    .avatar2 {
        position: absolute;
        top: 130px;
        left: -155px;
        z-index: 1;

        @include breakPoint(small) {
            left: 0;
        }
    }

    .avatar1 {
        position: absolute;
        top: 350px;
        right: -155px;
        z-index: 1;

        @include breakPoint(small) {
            right: 0;
        }
    }
}
